
import React, { useState, useEffect } from "react"

import { navigate, Link } from "../../services/routes" // "gatsby"

import Banner from "./banner"
import { isEmailValid, checkPassword } from "./common"
import { request_token, reset_password_with_token } from "../../services/auth"
import routes from "../../services/routes"

import smallform from "./smallform.module.css"

function validateRequestTokenForm({email1, email2}) {
	if (email1 !== email2) {
		return {valid: false, reason: "The emails you entered do not match."};
	}
	if (!isEmailValid(email1)) {
		return {valid: false, reason: "The email you entered is not valid."};
	}
	return {valid: true};
}

function validateResetPasswordForm({token, password1, password2}) {
	if (token.length===0) {
		return {valid: false, reason: "You must provide the security token from your email."};
	}
	const passwordCheck = checkPassword(password1);
	if (password1!==password2) {
		return {valid: false, reason: "The passwords you entered do not match."};
	}
	if (!passwordCheck.valid) {
		return passwordCheck;
	}
	return {valid: true};
}

const RequestTokenForm = ({done}) => {
	const [state, setState] = useState({
		formData: {
			email1: '',
			email2: '',
		},
		rejectReason: '',
	});
	const update = (event) => {
		setState({
			formData: {
				...state.formData,
				[event.target.name]: event.target.value,
			},
			rejectReason: '', // always reset when form changes
		});
	};
	const submit = async (event) => {
		event.preventDefault();
		let validation = validateRequestTokenForm(state.formData);
		if (validation.valid) {
			let result = await request_token(state.formData.email1);
			if (result.success) {
				done(state.formData.email1);
			} else {
				setState({
					...state,
					rejectReason: result.reason,
				});
			}
		} else {
			setState({
				...state,
				rejectReason: validation.reason,
			});
		}
	};

	return (
		<form className={smallform.smallform} id="requestTokenForm" action={routes.forgot_password} method="post">
			<input
				type="text"
				className={`form-control ${smallform.formTextTop}`}
				placeholder="Email address"
				name="email1"
				onChange={update}
			/>
			<input
				type="text"
				className={`form-control ${smallform.formTextBottom}`}
				placeholder="Confirm email address"
				name="email2"
				onChange={update}
			/>
			<br />
			{(state.rejectReason.length > 0) &&
				<p id="rtf_update_p" style={{color:"red"}}>{state.rejectReason}</p>
			}
			<button className="main_btn" onClick={submit}>
				Request Token
			</button>
		</form>
	);
}

const ResetPasswordForm = ({email, done}) => {
	const [state, setState] = useState({
		formData: {
			token: '',
			password1: '',
			password2: '',
		},
		rejectReason: '',
	});
	const update = (event) => {
		setState({
			formData: {
				...state.formData,
				[event.target.name]: event.target.value,
			},
			rejectReason: '', // always reset when form changes
		});
	};
	const submit = async (event) => {
		event.preventDefault();
		let validation = validateResetPasswordForm(state.formData);
		if (validation.valid) {
			let result = await reset_password_with_token({
				email,
				token: state.formData.token,
				password: state.formData.password1,
			});
			if (result.success) {
				done();
			} else {
				setState({
					...state,
					rejectReason: result.reason,
				});
			}
		} else {
			setState({...state, rejectReason: validation.reason});
		}
	};

	return (
		<form
			className={smallform.smallform}
		>
			<input
				type="text"
				className={`form-control ${smallform.formTextTop}`}
				placeholder="Security token (emailed to you)"
				name="token"
				onChange={update}
			/>
			<input
				type="password"
				className={`form-control ${smallform.formTextMiddle}`}
				placeholder="New password"
				name="password1"
				onChange={update}
			/>
			<input
				type="password"
				className={`form-control ${smallform.formTextBottom}`}
				placeholder="Confirm new password"
				name="password2"
				onChange={update}
			/>
			<br />
			{(state.rejectReason.length > 0) &&
				<p style={{color:"red"}} dangerouslySetInnerHTML={{__html:state.rejectReason}}></p>
			}
			<button className="main_btn" type="submit" onClick={submit}>
				Change Password
			</button>
		</form>
	);
}

const SuccessMessage = ({done}) => {
	useEffect(() => {
		setTimeout(done, 5000);
	});
	return (
		<p>
			Your password has been updated.
			You will be redirected to your
			&nbsp;<Link to={routes.account.index}>account page</Link>&nbsp;
			in a few seconds.
		</p>
	);
};

export default () => {
	const [state, setState] = useState({email:'', step:1});
	const beginStep2 = (email) => {
		setState({email, step: 2});
	};
	const beginStep3 = () => {
		setState({...state, step: 3});
	};
	const finish = () => {
		navigate(routes.account.index);
	}
	return (
		<>
			<Banner title="Password Reset" link={routes.forgot_password} sourceLink={routes.login} sourceName="Log In" />
			<div>
				<section style={{textAlign:"center"}}>
					<div className="container">
						<div style={{marginTop:"70px", marginBottom:"50px"}}>
							{ (state.step===1) &&
								<>
									<p id="requestTokenInstruction">
										Enter your account's email and we'll send you instructions to reset your password.
									</p>
									<RequestTokenForm done={beginStep2} />
								</>
							}
							{ (state.step===2) &&
								<>
									<p>
										You should receive an email within the next few minutes containing the security token needed below. If you do
										not receive the email, try checking your spam folder. If it's not there either, please double check the
										address you entered and ensure it corresponds to an account you already created.
									</p>
									<h5>{state.email}</h5>
									<ResetPasswordForm email={state.email} done={beginStep3}/>
								</>
							}
							{ (state.step===3) &&
								<SuccessMessage done={finish}/>
							}
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
