import React from "react"

import WebsiteLayout from '../../components/site/website_layout'
import ForgotPassword from '../../components/site/forgot_password'
import { RequireNoUser } from "../../components/check_user"

export default () => (
  <RequireNoUser>
    <WebsiteLayout>
      <ForgotPassword />
    </WebsiteLayout>
  </RequireNoUser>
);